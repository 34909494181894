'use client';

import cx from 'classix';
import Image from 'next/image';
import Link from 'next/link';
import {
  useLocale,
  useTranslations,
} from 'next-intl';
import {
  BsStarFill,
  BsStarHalf,
} from 'react-icons/bs';
import {
  FaFacebookF,
  FaInstagram,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import {
  DOWNLOAD_APP_LINKS,
  EXTERNAL_LINKS,
  ROUTER_LINKS,
  SOCIAL_MEDIA_LINKS,
} from '@/shared/constants/links-constants';

import styles from './home-page-footer-section.module.scss';

const fullStars = Array.from({ length: 4 }, (_, index) => index);

export default function HomePageFooterSection() {
  const footerTranslations = useTranslations('home.footer');
  const linksTranslations = useTranslations('global.links');
  const locale = useLocale();
  const country = locale.split('-')[1].toLowerCase();

  return (
    <section className={styles.homePageFooterWrapper}>
      <div className={styles.homePageFooterSection}>
        <div className={styles.homePageFooterColumnsWrapper}>
          <div className={styles.homePageFooterSection_desktop}>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.press}
              >
                {footerTranslations('press')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileMailbox')}
                target="_blank"
              >
                {footerTranslations('mailbox')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileContactUs')}
                target="_blank"
              >
                {footerTranslations('contact-us-header')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={ROUTER_LINKS.sitemap}
                target="_blank"
              >
                {footerTranslations('sitemap')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.reviews}
              >
                {linksTranslations('reviews')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={ROUTER_LINKS.blog}
                target="_blank"
              >
                {footerTranslations('blog')}
              </Link>
            </div>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.downloadApp}
              >
                {footerTranslations('download-apps')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.findingRoom}
              >
                {footerTranslations('finding-a-room')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.rentingOutRoom}
              >
                {footerTranslations('renting-out-a-room')}
              </Link>
            </div>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileMegaphone')}
                target="_blank"
              >
                {footerTranslations('megaphone')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileBookmark')}
                target="_blank"
              >
                {footerTranslations('bookmark-title-s')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileSupport')}
                target="_blank"
              >
                {footerTranslations('support')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileSettings')}
                target="_blank"
              >
                {footerTranslations('settings-title')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.affiliateProgram}
                target="_blank"
              >
                {footerTranslations('affiliate-program')}
              </Link>
            </div>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileHelpCenter')}
                target="_blank"
              >
                {footerTranslations('help-center')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.faq}
                target="_blank"
              >
                {footerTranslations('faq')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.fairHousing}
                target="_blank"
              >
                {footerTranslations('fair')}
              </Link>
            </div>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.terms}
              >
                {footerTranslations('terms')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.privacy}
              >
                {footerTranslations('privacy')}
              </Link>
              <div className={styles.mediaLinks}>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.facebook}
                  target="_blank"
                  aria-label="FacebookIcon"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.twitter}
                  target="_blank"
                  aria-label="TwitterIcon"
                >
                  <FaXTwitter />
                </Link>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.instagram}
                  target="_blank"
                  aria-label="InstagramIcon"
                >
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.homePageFooterSection_mobile}>
            <div className={styles.homePageFooterSectionMenu_column}>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.press}
              >
                {footerTranslations('press')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileContactUs')}
                target="_blank"
              >
                {footerTranslations('contact-us-header')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileBookmark')}
                target="_blank"
              >
                {footerTranslations('bookmark-title-s')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={ROUTER_LINKS.sitemap}
                target="_blank"
              >
                {footerTranslations('sitemap')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileSettings')}
                target="_blank"
              >
                {footerTranslations('settings-title')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.downloadApp}
              >
                {footerTranslations('download-apps')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.findingRoom}
              >
                {footerTranslations('finding-a-room')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.rentingOutRoom}
              >
                {footerTranslations('renting-out-a-room')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileMailbox')}
                target="_blank"
              >
                {footerTranslations('mailbox')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.affiliateProgram}
                target="_blank"
              >
                {footerTranslations('affiliate-program')}
              </Link>
            </div>
            <div className={styles.homePageFooterSectionMenu_column}>
              <div className={styles.footerLink}>
                <Image
                  src={`/images/country-flags-icons/${country}.svg`}
                  alt={country}
                  width={26}
                  height={18}
                />
              </div>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileMegaphone')}
                target="_blank"
              >
                {footerTranslations('megaphone')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileSupport')}
                target="_blank"
              >
                {footerTranslations('support')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.fairHousing}
                target="_blank"
              >
                {footerTranslations('fair')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={useAppFlyerLink('mobile', 'mobileHelpCenter')}
                target="_blank"
              >
                {footerTranslations('help-center')}
              </Link>
              <Link
                className={styles.footerLink}
                href={EXTERNAL_LINKS.faq}
                target="_blank"
              >
                {footerTranslations('faq')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.reviews}
              >
                {linksTranslations('reviews')}
              </Link>
              <Link
                className={styles.footerLink}
                prefetch={false}
                href={ROUTER_LINKS.blog}
                target="_blank"
              >
                {footerTranslations('blog')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.terms}
              >
                {footerTranslations('terms')}
              </Link>
              <Link
                className={styles.footerLink}
                href={ROUTER_LINKS.privacy}
              >
                {footerTranslations('privacy')}
              </Link>
              <div className={styles.mediaLinks}>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.facebook}
                  target="_blank"
                  aria-label="FacebookIcon"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.twitter}
                  target="_blank"
                  aria-label="TwitterIcon"
                >
                  <FaXTwitter />
                </Link>
                <Link
                  className={cx(styles.footerLink, styles.socialItem)}
                  href={SOCIAL_MEDIA_LINKS.instagram}
                  target="_blank"
                  aria-label="InstagramIcon"
                >
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homePageFooterApps}>
          <div className={styles.homePageFooterAppsRow}>
            <div className={styles.homePageFooterAppsRow_text}>
              {footerTranslations('download-free')}
            </div>
            <div className={styles.homePageFooterAppsRow_rating}>
              {fullStars.map((item) => (
                <div key={item} className={styles.startContainerItem}>
                  <BsStarFill />
                </div>
              ))}
              <div className={styles.startContainerItem}>
                <BsStarHalf />
              </div>
            </div>
          </div>

          <div className={styles.marketingList}>
            <Link
              aria-label="appStore"
              className={styles.marketingList_link}
              href={DOWNLOAD_APP_LINKS.appStore}
              rel="nofollow"
              target="_blank"
            >
              <Image
                src="/images/banner/tunes.webp"
                alt="full star"
                fill
              />
            </Link>
            <Link
              aria-label="googlePlay"
              className={styles.marketingList_link}
              href={DOWNLOAD_APP_LINKS.googlePlay}
              rel="nofollow"
              target="_blank"
            >
              <Image
                src="/images/banner/google-play.webp"
                alt="full star"
                fill
              />
            </Link>
          </div>
          <div className={styles.copyRight}>
            Roomster &copy;
            {' '}
            {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </section>
  );
}
